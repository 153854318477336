import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TourList from "./TourList";
import TourDetails from "./TourDetails";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/tours/:tenantId" element={<TourList />} />
        <Route path="/tour/:id" element={<TourDetails />} />

        {/* ... otras rutas */}
      </Routes>
    </Router>
  );
}

export default App;
