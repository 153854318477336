import React, { useState } from "react";
import "./TourCompanyInfo.css"; // Tu archivo de estilos CSS
import { FaFacebook, FaInstagram } from "react-icons/fa"; // Iconos de Font Awesome

function TourCompanyInfo({ companyInfo, onCurrencyChange, onLangChange }) {
  const [selectedCurrency, setSelectedCurrency] = useState("USD");
  const [selectedLang, setSelectedLang] = useState("es");

  const handleCurrencyChange = (e) => {
    setSelectedCurrency(e.target.value);
    onCurrencyChange(e.target.value);
  };

  const handlelangChange = (e) => {
    setSelectedLang(e.target.value);
    onLangChange(e.target.value);
  };

  return (
    <div className="tour-company-info">
      <img
        src={companyInfo.logo}
        alt="Logo de la empresa"
        className="company-logo"
      />
      <div className="company-details">
        <h3>{companyInfo.nombrecompañia}</h3>
        <p>Email: {companyInfo.email}</p>
        <p>Teléfono: {companyInfo.telefono}</p>
      </div>
      <div className="social-icons">
        <a
          href={companyInfo.facebook}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook />
        </a>
        <a
          href={companyInfo.instagram}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram />
        </a>
        <div className="currency-selector">
          <select id="lang" value={selectedLang} onChange={handlelangChange}>
            <option value="es">Español</option>
            <option value="en">Ingles</option>
          </select>
        </div>
        <div className="currency-selector">
          <select
            id="currency"
            value={selectedCurrency}
            onChange={handleCurrencyChange}
          >
            <option value="USD">USD</option>
            <option value="CRC">CRC</option>
          </select>
        </div>
      </div>
    </div>
  );
}

export default TourCompanyInfo;
