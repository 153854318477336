// SignaturePad.js
import React, { useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import "./SignaturePad.css"; // Archivo CSS para estilos
import { useTranslation } from "react-i18next";

const SignaturePad = ({ setFirma, setIsSigned }) => {
  const sigCanvas = useRef({});

  const handleClear = () => {
    sigCanvas.current.clear();
    setFirma(null);
    setIsSigned(false);
  };

  const handleEnd = () => {
    const firmaBase64 = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    setFirma(firmaBase64);
    setIsSigned(true);
  };

  const { t, i18n } = useTranslation();

  return (
    <div className="signature-container">
      <h3>{t("signaturepad.signature")}</h3>
      <SignatureCanvas
        penColor="green"
        canvasProps={{
          width: 500,
          height: 200,
          className: "sigCanvas",
        }}
        ref={sigCanvas}
        onEnd={handleEnd} // Evento que se dispara cuando se termina de dibujar
      />
      <div className="signature-buttons">
        <button type="button" onClick={handleClear}>
          {t("signaturepad.clean")}
        </button>
      </div>
    </div>
  );
};

export default SignaturePad;
