import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { app, db, storage } from "./firebaseConfig"; // Asegúrate de tener tu configuración de Firebase
import {
  doc,
  getDoc,
  addDoc,
  collection,
  runTransaction,
} from "firebase/firestore";
import {
  ref,
  uploadString,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage";
import "./TourDetails.css"; // Archivo CSS para estilos (opcional)
import "./BookingForm.css";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FaCalendarAlt } from "react-icons/fa"; // Icono de calendario
import SignatureCanvas from "react-signature-canvas";
import TourCompanyInfo from "./TourCompanyInfo"; // Importa el componente
import SignaturePad from "./SignaturePad";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { addDays, isSameDay } from "date-fns";
import AvailabilityDatePicker from "./AvailabilityDatePicker";
import { format, isBefore, startOfDay } from "date-fns";

function TourDetails() {
  const { id } = useParams(); // Obtiene el ID del tour de la URL
  const [tour, setTour] = useState(null);
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const [paypalClientId, setPaypalClientId] = useState(null);
  const [nombre, setNombre] = useState("");
  const [email, setEmail] = useState("");
  const [comprobante, setComprobante] = useState("");
  const [phone, setPhone] = useState("");
  const [numPersonas, setNumPersonas] = useState(1);
  const [fecha, setFecha] = useState(tomorrow);
  const [hora, setHora] = useState(""); // Estado para la hora
  const [errors, setErrors] = useState({}); // Estado para almacenar errores de validación
  const [firma, setFirma] = useState(null); // Estado para almacenar la firma
  const [error, setError] = useState("");
  const [pagoExitoso, setPagoExitoso] = useState(false); // Estado para mostrarel mensaje
  const [aceptaWaiver, setAceptaWaiver] = useState(false);
  const [isSigned, setIsSigned] = useState(false); // Estado para validar la firma
  const sigCanvas = useRef({}); // Referencia al componente SignatureCanvas
  const [selectedQuantities, setSelectedQuantities] = useState({});
  const [precioTotal, setPrecioTotal] = useState(0); // Inicializa precioTotal en 0
  const [companyInfo, setCompanyInfo] = useState(0); // Inicializa precioTotal en 0
  const [currency, setCurrency] = useState("USD");
  const [exchangeRate, setExchangeRate] = useState(530);
  const [lang, setLang] = useState("es");
  const { t, i18n } = useTranslation();
  const [disponibilidadMensaje, setDisponibilidadMensaje] = useState(null);
  const [selectedHour, setSelectedHour] = useState("");

  const [fechas, setFechasDisponibles] = useState(new Date());
  const [disponibilidad, setDisponibilidad] = useState([]);
  const [selected, setSelected] = useState(new Date());

  const isValid =
    nombre && email && numPersonas && fecha && hora && isSigned && phone;

  const isValidSinpe =
    nombre &&
    email &&
    numPersonas &&
    fecha &&
    hora &&
    isSigned &&
    phone &&
    comprobante;

  const handleCurrencyChange = (newCurrency) => {
    setCurrency(newCurrency);
  };

  const handlelangChange = (newLang) => {
    setLang(newLang);
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  const formatPrice = (price) => {
    return currency === "USD"
      ? `$${price}`
      : `₡${(price * exchangeRate).toFixed(0)}`; // Suponiendo un tipo de cambio de 530 CRC por 1 USD
  };

  useEffect(() => {
    // Llamada a una API para obtener el tipo de cambio
    const fetchExchangeRate = async () => {
      try {
        const response = await axios.get(
          "https://api.exchangerate-api.com/v4/latest/USD"
        );
        setExchangeRate(response.data.rates.CRC);
      } catch (error) {
        console.error("Error fetching exchange rate:", error);
      }
    };

    fetchExchangeRate();
  }, []);

  useEffect(() => {
    if (tour && tour.opciones) {
      // Verificar si tour y tour.opciones existen
      setDisponibilidad(tour.disponibilidad || []);

      setPrecioTotal(calculateTotalPrice());
    }
  }, [tour, selectedQuantities]); // Dependencias: tour y selectedQuantities

  useEffect(() => {
    const fetchTourDetails = async () => {
      try {
        const tourDocRef = doc(db, "tours", id); // Referencia al documento del tour
        const tourDocSnap = await getDoc(tourDocRef);

        if (tourDocSnap.exists()) {
          const tourData = tourDocSnap.data();

          setTour({ id: tourDocSnap.id, ...tourDocSnap.data() }); // Almacena los detalles del tour
          // Obtener el clienteId del tour
          const clienteDocRef = doc(db, "clientes", tourData.tenantId); // Suponiendo que tienes un campo tenantId en el documento del tour
          const clienteDocSnap = await getDoc(clienteDocRef);

          if (clienteDocSnap.exists()) {
            setCompanyInfo(clienteDocSnap.data());
            setPaypalClientId(clienteDocSnap.data().paypalClientId);
          } else {
            console.log("No se encontró el cliente.");
          }
          const fechas = tour.disponibilidad.reduce((fechas, horario) => {
            // Verificar si hay al menos un horario disponible para esta fecha
            if (
              !fechas.find(
                (fecha) =>
                  fecha === horario.fecha &&
                  horario.capacidad > horario.reservados
              )
            ) {
              fechas.push(horario.fecha);
            }
            return fechas;
          }, []);

          setFechasDisponibles(fechas);

          setSelectedQuantities(
            tour.opciones.reduce((acc, opcion) => {
              acc[opcion.nombre] = 0;
              return acc;
            }, {})
          );
        } else {
          console.log("No se encontró el tour.");
          // Puedes manejar el caso de que no se encuentre el tour (redirigir, mostrar mensaje, etc.)
        }
      } catch (error) {
        console.error("Error: ", error);
      }
    };

    fetchTourDetails();
  }, [id]); // Ejecuta el efecto solo cuando cambia el ID

  const isDateAvailable = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    const today = startOfDay(new Date());

    // Verificar si la fecha está en el futuro y existe en la disponibilidad
    const dateExists = disponibilidad.some((d) => d.fecha === formattedDate);

    if (!dateExists) {
      return false;
    }

    // Verificar si hay al menos una hora disponible para reservar
    const hoursAvailable = disponibilidad.some(
      (d) => d.fecha === formattedDate && d.capacidad > d.reservados
    );

    return !isBefore(date, today) && hoursAvailable;
  };
  const dayClassName = (date) => {
    return isDateAvailable(date) ? "date-available" : "date-unavailable";
  };

  const getAvailableHours = () => {
    const formattedDate = format(fecha, "yyyy-MM-dd");
    const availableHours = disponibilidad
      .filter((d) => d.fecha === formattedDate && d.reservados < d.capacidad)
      .map((d) => d.hora);
    return availableHours;
  };

  const handleHourChange = (e) => {
    setHora(e.target.value);
  };

  const handleQuantityChange = (optionIndex, newQuantity) => {
    setSelectedQuantities((prevQuantities) => ({
      ...prevQuantities,
      [optionIndex]: newQuantity,
    }));
  };

  const calculateTotalPrice = () => {
    let total = 0;
    for (let i = 0; i < tour.opciones.length; i++) {
      const quantity = selectedQuantities[i] || 0; // Cantidad seleccionada o 0 si no está definida
      total += quantity * parseFloat(tour.opciones[i].precio);
    }
    return total.toFixed(2);
  };

  const pagosinpemovil = async () => {
    if (!comprobante) {
      setError("Debe ingresar el número de comprobante.");
      return;
    }

    try {
      let firmaUrl = null; // Variable para almacenar la URL de la firma
      if (firma) {
        // Convertir la firma base64 a un Blob
        const byteCharacters = atob(firma.split(",")[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {
          type: "image/webp",
        });

        // Subir la firma al almacenamiento de Firebase (si es necesario)
        const storageRef = ref(storage, `firmas/${nombre}-${fecha}.png`);
        await uploadBytes(storageRef, blob);

        // Obtener la URL de descarga de la firma desde Firebase Storage
        firmaUrl = await getDownloadURL(storageRef);
      }
      const precioTotal = calculateTotalPrice(); // Calcular el precio total
      const opcionesSeleccionadas = tour.opciones
        .map((opcion, index) => ({
          nombre: opcion.nombre,
          precio: opcion.precio,
          cantidad: selectedQuantities[index] || 0, // Obtener la cantidad seleccionada o 0 si no hay
        }))
        .filter((opcion) => opcion.cantidad > 0); // Filtrar las opciones con cantidad 0

      const bookingData = {
        // ... datos de la reserva (tourId, nombre, email, etc.)
        tourId: id,
        nombre, // Información del formulario
        email,
        fecha, // Enviar la fecha como string
        hora,
        phone,
        metododepago: "Sinpe Movil",
        tourNombre: tour.nombre,
        status: "Pendiente de Validacion",
        orderId: comprobante, // ID de la orden de PayPal
        //payerId: details.payer.payer_id, // ID del pagador en PayPal
        firma: firmaUrl, // Agregar la firma al objeto bookingData
        precioTotal,
        tenantId: tour.tenantId,
        opcionesSeleccionadas: opcionesSeleccionadas, // Agregar las opciones seleccionadas
      };

      await addDoc(collection(db, "bookings"), bookingData);
      // Mostrar mensaje de éxito y limpiar campos
      setPagoExitoso(true);
      setNombre("");
      setEmail("");
      setNumPersonas(1);
      setComprobante("");
      setFecha("");
      setHora("");
      setFirma(null);
      handleClear();
      // Mostrar mensaje de éxito y redirigir al usuario
    } catch (error) {
      // Manejar errores al guardar la reserva
    }
  };

  const handleClear = () => {
    sigCanvas.current.clear();
    setFirma(null);
  };

  const handleGenerate = () => {
    const firmaBase64 = sigCanvas.current
      .getTrimmedCanvas()
      .toDataURL("image/png");
    setFirma(firmaBase64);
  };

  if (!tour) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Cargando detalles del tour...</p>
      </div>
    );
  }

  const handleBooking = async () => {
    setError(null); // Limpiar errores anteriores

    // Verificar disponibilidad (usando tour.disponibilidad)
    const horarioSeleccionado = tour.disponibilidad.find(
      (h) => h.fecha === fecha.toISOString().split("T")[0] && h.hora === hora
    );

    if (horarioSeleccionado) {
      const { capacidad, reservados } = horarioSeleccionado;
      const totalPersonas = Object.values(selectedQuantities).reduce(
        (acc, cantidad) => acc + cantidad,
        0
      );
      if (reservados + totalPersonas > capacidad) {
        setError(
          "No hay suficientes cupos disponibles para esta fecha y hora."
        );
        setDisponibilidadMensaje(null);
        return;
      } else {
        setDisponibilidadMensaje(
          "¡Hay cupos disponibles! Puedes reservar ahora."
        );
      }
    } else {
      setError("No se encontró la disponibilidad para esta fecha y hora.");
      setDisponibilidadMensaje(null);
      return;
    }

    // ... (continuar con la lógica de pago si hay disponibilidad)
  };

  // Calcular la fecha mínima permitida (día siguiente al día actual)

  return (
    <div className="tour-details-container" style={{ marginTop: "100px" }}>
      {companyInfo && (
        <TourCompanyInfo
          companyInfo={companyInfo}
          onCurrencyChange={handleCurrencyChange}
          onLangChange={handlelangChange}
        />
      )}

      {/* Mostrar la información de la empresa */}
      <img src={tour.image} alt={tour.nombre} className="tour-details-image" />
      <div className="tour-details-info">
        <h2>Tour {tour.nombre}</h2>
        <p>{lang === "es" ? tour.descripcion.es : tour.descripcion.en}</p>

        <h2>{t("tourDetails.packages")}</h2>

        {tour.opciones.map((opcion, index) => (
          <div key={index} className="opcion-input">
            <label>
              {opcion.nombre} - {formatPrice(opcion.precio)}
            </label>
          </div>
        ))}
        {tour && ( // Mostrar formulario solo si el tour está cargado
          <div className="booking-form">
            <h3>{t("tourDetails.reservationtour")}</h3>
            <label htmlFor="fecha">{t("tourDetails.name")}:</label>
            <input
              type="text"
              placeholder={t("tourDetails.name")}
              value={nombre}
              onChange={(e) => setNombre(e.target.value)}
            />
            <label htmlFor="fecha">{t("tourDetails.email")}:</label>
            <input
              type="email"
              placeholder={t("tourDetails.email")}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <label htmlFor="fecha">{t("tourDetails.phone")}:</label>
            <input
              type="number"
              placeholder={t("tourDetails.phone")}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <label htmlFor="fecha">{t("tourDetails.price")}:</label>
            {tour.opciones.map((opcion, index) => (
              <div key={index} className="opcion-input">
                <label>
                  {opcion.nombre} - {formatPrice(opcion.precio)}
                </label>
                <div className="quantity-selector">
                  <button
                    onClick={() =>
                      handleQuantityChange(
                        index,
                        Math.max(0, (selectedQuantities[index] || 0) - 1)
                      )
                    }
                  >
                    -
                  </button>
                  <span>{selectedQuantities[index] || 0}</span>
                  <button
                    onClick={() =>
                      handleQuantityChange(
                        index,
                        (selectedQuantities[index] || 0) + 1
                      )
                    }
                  >
                    +
                  </button>
                </div>
              </div>
            ))}
            {/* Contenedor para el DatePicker */}
            <div className="fecha-container">
              <label htmlFor="fecha">{t("tourDetails.date")}:</label>

              <DatePicker
                className="date-picker"
                selected={fecha}
                dateFormat="dd/MM/yyyy"
                locale={"es"}
                minDate={tomorrow} // Establecer la fecha mínima permitida
                onChange={(date) => setFecha(date)}
                dayClassName={dayClassName}
                filterDate={isDateAvailable} // Deshabilita fechas no disponibles
              />
            </div>
            <label htmlFor="fecha">{t("tourDetails.hour")}:</label>
            <select value={selectedHour} onChange={handleHourChange}>
              <option value="">Seleccione una hora</option>
              {getAvailableHours().map((hour, index) => (
                <option key={index} value={hour}>
                  {hour}
                </option>
              ))}
            </select>
            <div className="waiver-container">
              <SignaturePad setFirma={setFirma} setIsSigned={setIsSigned} />{" "}
              {/* Componente de firma */}
              <label htmlFor="waiver">
                {t("tourDetails.termspart1")}{" "}
                <a
                  href="/terminos-y-condiciones"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("tourDetails.termspart2")}
                </a>{" "}
                {t("tourDetails.termspart3")}{" "}
                <a
                  href="/politica-de-privacidad"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {t("tourDetails.termspart4")}
                </a>
                .
              </label>
            </div>
            <div className="precio-total">
              <h3>{t("tourDetails.totalprice")}:</h3>
              <p>{formatPrice(calculateTotalPrice())}</p>
            </div>
            {errors.nombre && <p className="error-message">{errors.nombre}</p>}{" "}
            {errors.email && <p className="error-message">{errors.email}</p>}{" "}
            {errors.fecha && <p className="error-message">{errors.fecha}</p>}{" "}
            {errors.hora && <p className="error-message">{errors.hora}</p>}{" "}
            {errors.isSigned && (
              <p className="error-message">{errors.isSigned}</p>
            )}{" "}
            {pagoExitoso && (
              <div className="success-message">{t("tourDetails.payexit")}</div>
            )}
            {disponibilidadMensaje && (
              <p className="disponibilidad-message">{disponibilidadMensaje}</p>
            )}
            {/* Opciones de pago */}
            <div className="payment-options">
              {currency === "USD" ? (
                <div>
                  {isValid && ( // Mostrar el botón solo si es válido
                    <PayPalScriptProvider
                      options={{
                        "client-id": paypalClientId,
                      }}
                    >
                      <PayPalButtons
                        forceReRender={[precioTotal, tour]}
                        createOrder={(data, actions) => {
                          // Lógica para crear la orden de pago en PayPal
                          return actions.order.create({
                            purchase_units: [
                              {
                                amount: {
                                  value: calculateTotalPrice(), // Precio del tour
                                },
                              },
                            ],
                          });
                        }}
                        onApprove={(data, actions) => {
                          return actions.order
                            .capture()
                            .then(async (details) => {
                              try {
                                const opcionesSeleccionadas = tour.opciones
                                  .map((opcion, index) => ({
                                    nombre: opcion.nombre,
                                    precio: opcion.precio,
                                    cantidad: selectedQuantities[index] || 0,
                                  }))
                                  .filter((opcion) => opcion.cantidad > 0);

                                const totalPersonas = Object.values(
                                  selectedQuantities
                                ).reduce((acc, cantidad) => acc + cantidad, 0);

                                // Realizar la reserva y actualizar la disponibilidad en una transacción
                                await runTransaction(
                                  db,
                                  async (transaction) => {
                                    // 1. Obtener el documento de disponibilidad
                                    const disponibilidadDocRef = doc(
                                      db,
                                      "tours",
                                      id
                                    );
                                    const disponibilidadDocSnap =
                                      await transaction.get(
                                        disponibilidadDocRef
                                      );

                                    if (!disponibilidadDocSnap.exists()) {
                                      setDisponibilidadMensaje(
                                        "No se encontró la disponibilidad para este tour."
                                      );
                                      throw new Error(
                                        "No se encontró la disponibilidad para este tour."
                                      );
                                    }

                                    const horarios =
                                      disponibilidadDocSnap.data()
                                        .disponibilidad;
                                    const horarioIndex = horarios.findIndex(
                                      (h) =>
                                        h.fecha ===
                                          fecha.toISOString().split("T")[0] &&
                                        h.hora === hora
                                    );

                                    if (horarioIndex === -1) {
                                      setDisponibilidadMensaje(
                                        "No se encontró el horario seleccionado."
                                      );
                                      throw new Error(
                                        "No se encontró el horario seleccionado."
                                      );
                                    }

                                    // 2. Verificar disponibilidad dentro de la transacción
                                    if (
                                      horarios[horarioIndex].reservados +
                                        totalPersonas >
                                      horarios[horarioIndex].capacidad
                                    ) {
                                      setDisponibilidadMensaje(
                                        "No hay suficientes cupos disponibles para esta fecha y hora."
                                      );
                                      throw new Error(
                                        "No hay suficientes cupos disponibles para esta fecha y hora."
                                      );
                                    }

                                    // 3. Crear la reserva
                                    let firmaUrl = null; // Variable para almacenar la URL de la firma
                                    if (firma) {
                                      const storageRef = ref(
                                        storage,
                                        `firmas/${nombre}-${fecha}.png`
                                      ); // Referencia al archivo en Storage
                                      await uploadString(
                                        storageRef,
                                        firma,
                                        "data_url"
                                      ); // Subir la firma a Storage
                                      firmaUrl = await getDownloadURL(
                                        storageRef
                                      ); // Obtener la URL de la firma
                                    }
                                    const bookingData = {
                                      // ... datos de la reserva (tourId, nombre, email, etc.)
                                      tourId: id,
                                      nombre, // Información del formulario
                                      email,
                                      numPersonas: totalPersonas,
                                      fecha,
                                      hora,
                                      phone,
                                      tourNombre: tour.nombre,
                                      metododepago: "Paypal",
                                      status: "Aprovado",
                                      orderId: data.orderID, // ID de la orden de PayPal
                                      payerId: details.payer.payer_id, // ID del pagador en PayPal
                                      firma: firmaUrl, // Agregar la firma al objeto bookingData
                                      precioTotal,
                                      tenantId: tour.tenantId,
                                      opcionesSeleccionadas:
                                        opcionesSeleccionadas,
                                    };

                                    await addDoc(
                                      collection(db, "bookings"),
                                      bookingData
                                    );
                                  }
                                );

                                setPagoExitoso(true);
                                setNombre("");
                                setEmail("");
                                setNumPersonas(1);
                                setFecha("");
                                setHora("");
                                setFirma(null);
                                handleClear();
                              } catch (error) {
                                console.error(
                                  "Error al crear la reserva: ",
                                  error
                                );
                                setError(
                                  "Ocurrió un error al procesar la reserva."
                                );
                              }
                            });
                        }}
                      />
                    </PayPalScriptProvider>
                  )}
                </div>
              ) : (
                <div>
                  {isValid && (
                    <div>
                      <h3>Pago Sinpe Movil</h3>
                      <label>
                        Por favor realizar el pago al numero{" "}
                        {companyInfo.numerosinpe} e ingresar el numero de
                        comprobante
                      </label>

                      <input
                        type="comprobante"
                        placeholder="Numero de comprobante"
                        value={comprobante}
                        onChange={(e) => setComprobante(e.target.value)}
                      />
                      {error && <p className="error-message">{error}</p>}
                      {disponibilidadMensaje && ( // Mostrar mensaje de disponibilidad
                        <p className="disponibilidad-message">
                          {disponibilidadMensaje}
                        </p>
                      )}
                      <div>
                        <button
                          className="sinpe-button"
                          onClick={handleBooking}
                        >
                          Pay with Sinpe Movil
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      {/* ... aquí puedes agregar un botón para reservar el tour */}
    </div>
  );
}

function CustomInput({ value, onClick }) {
  return (
    <div className="custom-input" onClick={onClick}>
      {value || "dd/mm/yyyy"} {/* Mostrar "dd/mm/yyyy" si no hay valor */}
      <FaCalendarAlt className="calendar-icon" /> {/* Icono de calendario */}
    </div>
  );
}

export default TourDetails;
