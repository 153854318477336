import React, { useEffect, useState } from "react";
import { db } from "./firebaseConfig"; // Asegúrate de tener tu configuración de Firebase
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import "./TourList.css"; // Archivo CSS para estilos
import { useNavigate } from "react-router-dom"; // Importa useNavigate
import { Link, useParams } from "react-router-dom";
import TourCompanyInfo from "./TourCompanyInfo"; // Importa el componente
import { useTranslation } from "react-i18next";

function TourList() {
  const [tours, setTours] = useState([]);
  const navigate = useNavigate(); // Obtén la función navigate
  const { tenantId } = useParams(); // Obtener tenantId de la URL
  const [companyInfo, setCompanyInfo] = useState(0); // Inicializa precioTotal en 0
  const [currency, setCurrency] = useState("USD");
  const [exchangeRate, setExchangeRate] = useState(530);
  const [lang, setLang] = useState("es");
  const { t, i18n } = useTranslation();

  useEffect(() => {
    const fetchTours = async () => {
      try {
        const toursQuery = query(
          collection(db, "tours"),
          where("tenantId", "==", tenantId)
        );
        const querySnapshot = await getDocs(toursQuery);
        const toursData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setTours(toursData);

        const clienteDocRef = doc(db, "clientes", tenantId); // Suponiendo que tienes un campo tenantId en el documento del tour
        const clienteDocSnap = await getDoc(clienteDocRef);

        if (clienteDocSnap.exists()) {
          setCompanyInfo(clienteDocSnap.data());
        } else {
          console.log("No se encontró el cliente.");
        }
      } catch (error) {
        console.error("Error al obtener los tours:", error);
        // Manejar el error (mostrar un mensaje, etc.)
      }
    };

    fetchTours();
  }, [tenantId]); // Volver a cargar los tours si cambia el tenantId

  const handleCurrencyChange = (newCurrency) => {
    setCurrency(newCurrency);
  };
  const handlelangChange = (newLang) => {
    setLang(newLang);
  };

  useEffect(() => {
    i18n.changeLanguage(lang);
  }, [lang, i18n]);

  return (
    <div className="tour-list" style={{ marginTop: "100px" }}>
      {companyInfo && (
        <TourCompanyInfo
          companyInfo={companyInfo}
          onCurrencyChange={handleCurrencyChange}
          onLangChange={handlelangChange}
        />
      )}
      {tours.length === 0 ? (
        <p>No hay tours disponibles para este cliente.</p>
      ) : (
        tours.map((tour) => (
          <div key={tour.id} className="tour-card">
            <img src={tour.image} alt={tour.nombre} className="tour-image" />
            <div className="tour-details">
              <h3>{tour.nombre}</h3>
              <p>
                {lang === "es"
                  ? tour.descripcionBreve.es
                  : tour.descripcionBreve.en}
              </p>
              <h2>{t("tourList.package")}</h2>
              <div className="tour-options">
                {tour.opciones && tour.opciones.length > 0 ? (
                  tour.opciones.map((opcion, index) => (
                    <div key={index} className="tour-option">
                      <label>
                        {opcion.nombre} - ${opcion.precio}
                      </label>
                    </div>
                  ))
                ) : (
                  <p>{t("tourList.notoptions")}</p>
                )}
              </div>
              <button
                className="view-details-button"
                onClick={() => navigate(`/tour/${tour.id}`)} // Navega a la ruta con el ID del tour
              >
                {t("tourList.moredetails")}
              </button>
            </div>
          </div>
        ))
      )}
    </div>
  );
}

export default TourList;
