import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { db } from "./firebaseConfig"; // Asegúrate de tener tu configuración de Firestore aquí

function AvailabilityDatePicker({ tourId }) {
  const [startDate, setStartDate] = useState(new Date());
  const [dates, setDates] = useState([]);

  useEffect(() => {
    const fetchAvailability = async () => {
      const tourRef = db.collection("tours").doc(tourId);
      const tourDoc = await tourRef.get();
      if (tourDoc.exists) {
        const disponibilidad = tourDoc.data().disponibilidad;
        setDates(disponibilidad);
      }
    };

    fetchAvailability();
  }, [tourId]);

  const isDateAvailable = (date) => {
    const dateStr = date.toISOString().split("T")[0]; // Formato YYYY-MM-DD
    const availability = dates.find((d) => d.fecha === dateStr);
    return availability && availability.cuadras - availability.reservados > 0;
  };

  const highlightWithRanges = [
    {
      "react-datepicker__day--highlighted-custom-available": dates
        .filter((d) => d.cuadras - d.reservados > 0)
        .map((d) => new Date(d.fecha)),
    },
    {
      "react-datepicker__day--highlighted-custom-unavailable": dates
        .filter((d) => d.cuadras - d.reservados <= 0)
        .map((d) => new Date(d.fecha)),
    },
  ];

  return (
    <DatePicker
      selected={startDate}
      onChange={(date) => setStartDate(date)}
      highlightDates={highlightWithRanges}
      dayClassName={(date) => {
        const dateStr = date.toISOString().split("T")[0];
        const availability = dates.find((d) => d.fecha === dateStr);
        if (availability) {
          return availability.capacidad - availability.reservados > 0
            ? "custom-available"
            : "custom-unavailable";
        }
        return null;
      }}
    />
  );
}

export default AvailabilityDatePicker;
